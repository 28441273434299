<template>
    <div>
      {{ formattedDate }}
    </div>
  </template>
  
  <script>
  export default {
    name: 'DateFormatter',
    props: {
      date: {
        type: String,
        required: true
      }
    },
    computed: {
      formattedDate() {
        return this.formatDate(this.date);
      }
    },
    methods: {
      formatDate(dateString) {
        const date = new Date(dateString);
        const monthNames = [
          'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
          'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
        ];
        const day = date.getDate();
        const month = monthNames[date.getMonth()];
        return `${day} ${month}`;
      }
    }
  }
  </script>
  