<template>
  <div class="modal" v-if="visible">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <h2>Редактирование мастера</h2>
      <form @submit.prevent="save">
        <div class="form__group field">
          <input
            type="text"
            class="form__field"
            placeholder="Имя Мастер"
            required
            v-model="master.firstName"
            id="firstName"
          />
          <label for="firstName" class="form__label">Имя</label>
        </div>
        <div class="form__group field">
          <input
            type="text"
            class="form__field"
            placeholder="Фамилия Мастера"
            required
            v-model="master.lastName"
            id="lastName"
          />
          <label for="lastName" class="form__label">Фамилия</label>
        </div>
        <div class="form__group field">
          <input
            type="tel"
            class="form__field"
            placeholder="Телефон"
            required
            v-model="master.phone"
            id="phone"
          />
          <label for="phone" class="form__label">Номер телефона</label>
        </div>
        <div class="form__group field">
          <input
            type="text"
            class="form__field"
            placeholder="Телеграм"
            v-model="master.telegram"
            id="telegram"
          />
          <label for="telegram" class="form__label">Телеграм</label>
        </div>
        <div class="form__group field">
          <input
            type="text"
            class="form__field"
            placeholder="Инстаграм"
            v-model="master.inst"
            id="inst"
          />
          <label for="inst" class="form__label">Инстаграм</label>
        </div>
        <div class="form__group field">
          <input
            type="text"
            class="form__field"
            placeholder="Должность"
            required
            v-model="master.post"
            id="post"
          />
          <label for="post" class="form__label">Должность</label>
        </div>
        <div class="form__group field">
          <input
            type="text"
            class="form__field"
            placeholder="Логин"
            required
            v-model="master.username"
            id="userName"
          />
          <label for="userName" class="form__label">Логин</label>
        </div>
        <div class="form-group">
          <label for="image">Изображение</label>
          <input type="file" @change="onFileChange" />
        </div>
        <ButtonComponent
          :name="'Редактировать'"
          :type="submit"
          style="margin-top: 20px"
        />
      </form>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "@/components/ButtonComponent.vue";

export default {
  components: {
    ButtonComponent,
  },
  props: {
    master: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedFile: null,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    save() {
      this.$emit("save", { ...this.master, file: this.selectedFile });
      this.closeModal();
    },
    onFileChange(event) {
      this.selectedFile = event.target.files[0];
    },
  },
};
</script>

<style scoped>
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: rgba(56, 56, 56, 0.6);
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  backdrop-filter: blur(30px);
  border-radius: 30px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

h2 {
  text-align: center;
}

input[type="file"]::file-selector-button {
  margin-right: 20px;
  margin-left: 5px;
  border: none;
  background: #f4bc83;
  padding: 5px;
  border-radius: 25px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

input[type="file"]::file-selector-button:hover {
  background: #f4bc83;
}
</style>
