<template>
  <div class="home-care-all">
    <div class="home-care" v-for="homeCare in homeCares" :key="homeCare">
      <div class="home-care-image-all">
        <img class="home-care-img" :src="homeCare?.image1" alt="" />
        <img class="home-care-img" :src="homeCare?.image2" alt="" />
      </div>
      <div class="home-care-text" v-html="homeCare.text"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      homeCares: [
        {
          image1: require("../assets/homeCare1.jpg"),
          image2: require("../assets/homeCare2.jpg"),
          text: "<h2>Комплект для домашнего ухода L'AMARI Recovery Shampoo 500 ml + Mask 500 ml</h2> <br/> Безсульфатный шампунь для волос L'AMARI Recovery Shampoo имеет нежную формулу для бережного и мягкого очищения. Использование: нанести на влажные волосы, массажными движениями промыть кожу головы. Смыть. При необходимости повторить. Подходит для Нормальной кожи головы Маска для волос L'AMARI Recovery Mask, обогащенная аргановым маслом, обеспечивает интенсивное увлажнение и восстановление структуры ваших волос.<br/><br/> Способствует приданию невероятного блеска и живости вашим локонам, придавая им сияющую и шелковистую текстуру. <br/><br/><ol><li>Цена за комплект - <strong>2300₽</strong></li><li>Цена по отдельности - <strong>1200₽</strong></li></ol>",
        },
        {
          image1: require("../assets/homeCare3.jpg"),
          image2: require("../assets/homeCare4.jpg"),
          text: "<h2>Комплект для домашнего ухода L'AMARI Delicate Shampoo 500 ml + Mask 500 ml</h2> <br/> Деликатный шампунь L'AMARI Delicate Cleaning Shampoo мягко удаляет загрязнения с кожи головы и волос, обеспечивая бережный уход. В его составе отсутствуют сульфаты и парабены. Для нормальной и жирной кожи головы. <br/><br/> Эффективная маска L'AMARI Delicate Mask предназначена для увлажнения сухих и поврежденных волос. <br/><br/><ol><li>Цена за комплект - <strong>2300₽</strong></li><li>Цена по отдельности - <strong>1200₽</strong></li></ol>",
        },
        {
          image1: require("../assets/homeCare5.jpg"),
          image2: require("../assets/homeCare6.jpg"),
          text: "<h2>Комплект домашнего ухода ZOOM Magic Unicorn. Шампунь 250 ml + маска 250 ml</h2> <br/> Деликатный ежедневный шампунь бережно очищает кожу головы и волосы, без эффекта утяжеления. Содержит экстракт ягод Асаи, Маракуйи и Пантенол. Для нормальной и сколонной к жирности кожи головы. Маска-кондиционер увлажняющая ZOOM Magic Unicorn Mask 250 ml. <br/><br/> Легкая и интенсивно увлажняющая маска-кондиционер. В состав входит питательное масло Копайбы, масло Ожон и Арганы, которые обладают глубокими кондиционирующими свойствами. <br/><br/><ol><li>Цена за комплект - <strong>1200₽</strong></li><li>Цена по отдельности - <strong>650₽</strong></li></ol>",
        },
        {
          image1: require("../assets/homeCare7.jpg"),
          image2: require("../assets/homeCare8.webp"),
          text: "<h2>Масло виноградной косточки Happy Hair Kerasist 50 мл</h2> <br/> Масло для восстановления и питания поврежденных волос. <br/> Рекомендуется использовать для ослабленных волос любого типа. <br/><br/><ol><li>Цена - <strong>800₽</strong></li></ol>",
        },
        {
          image1: require("../assets/homeCare9.jpg"),
          image2: require("../assets/homeCare10.jpg"),
          text: "<h2>Шампунь увлажняющий для домашнего ухода MAXWELL Result Shampoo 250 ml. Шампунь 250 ml + маска 250 ml</h2> <br/> Увлажняющий шампунь для волос MAXWELL RESULT Shampoo представляет собой идеальное решение для ваших волос. Нежная формула обеспечивает мягкое очищение, насыщая волосы кератином, аргановым маслом, жирными кислотами, протеинами и другими ценными питательными компонентами. Этот шампунь эффективно справляется с основными проблемами волос, такими как избыточная пористость и хрупкость волосяного стержня. <br/><br/> Для сухой и нормальной кожи головы Маска восстанавливающая MAXWELL Result Mask 250 ml Маска для волос MAXWELL RESULT Mask, обогащенная аргановым маслом, обеспечивает интенсивное увлажнение и восстановление структуры ваших волос, способствует приданию невероятного блеска и живости вашим локонам, придавая им сияющую и шелковистую текстуру. <br/><br/><ol><li>Цена за комплект - <strong>1200₽</strong></li><li>Цена по отдельности - <strong>650₽</strong></li></ol>",
        },
        {
          image1: require("../assets/homeCare11.jpg"),
          image2: require("../assets/homeCare12.jpg"),
          text: "<h2>Комплект домашнего ухода MAXWELL Keratin. Шампунь 250 ml + маска 250 ml</h2> <br/> Нежный шампунь MAXWELL Keratin Shampoo мягко удаляет загрязнения с кожи головы и волос, обеспечивая бережный уход. Для нормальной и склонной с жирности кожи головы. <br/><br/> Эффективная маска MAXWELL Keratin Mask предназначена для интенсивного питания и восстановления сухих и поврежденных волос. В ее состав включены питательное масло Бабассу, а также масло Пачули и Омега-9, которые обладают глубокими кондиционирующими свойствами. <br/><br/><ol><li>Цена за комплект - <strong>1200₽</strong></li><li>Цена по отдельности - <strong>650₽</strong></li></ol>",
        },
      ],
    };
  },
  methods: {
    async getHomeCare() {
      try {
        const response = await axios.get(
          "https://leon-back-hiss.amvera.io/home-care"
        );
        this.homeCares = response.data;
      } catch (error) {
        console.error("Ошибка при получении данных домашнего ухода:", error);
        // Добавьте обработку ошибок, например, вывод сообщения об ошибке пользователю
      }
    },
  },
  async mounted() {
    // await this.getHomeCare();
  },
};
</script>

<style scoped>
.home-care {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  justify-content: space-evenly;
}

.home-care:nth-child(2n) {
  flex-direction: row-reverse;
}

.home-care-img {
  margin-right: 20px;
  border-radius: 20px;
  max-width: 375px;
}

.home-care-text {
  font-family: "Montserrat Medium";
  max-width: 1040px;
  /* max-height: 330px; */
  word-break: break-word;
  margin-top: 10px;
  line-height: 23px;
}

.home-care-text > :is(h1, h2, h3) {
  margin-bottom: 20px;
}

.home-care-text > :is(ol) {
  margin-left: 20px;
  margin-top: 20px;
}

.home-care-text > :is(ol) > :is(li) > :is(strong) {
  color: #f4bc83;
}

@media screen and (max-width: 1440px) {
  .home-care-text {
    max-width: 500px;
  }
}

@media screen and (max-width: 1024px) {
  .home-care-text {
    max-width: 400px;
    font-size: 14px;
  }
  .home-care-img {
    max-width: 250px;
  }
}

@media screen and (max-width: 768px) {
  .home-care-text {
    max-width: 400px;
    font-size: 14px;
  }
  .home-care-img {
    max-width: 250px;
  }
}

@media screen and (max-width: 480px) {
  .home-care {
    flex-direction: column;
    margin-bottom: 50px;
  }
  .home-care-image-all {
    display: flex;
  }
  .home-care-img {
    max-width: 180px;
  }
  .home-care-text {
    max-width: 360px;
    margin-top: 20px;
  }
  .home-care:nth-child(2n) {
    flex-direction: column;
  }
}

@media screen and (max-width: 375px) {
  .home-care-img {
    max-width: 140px;
  }
}

@media screen and (max-width: 320px) {
  .home-care-img {
    max-width: 130px;
  }
  .home-care {
    align-items: flex-end;
  }
  .home-care-text {
    margin-left: 10px;
  }
}
</style>
