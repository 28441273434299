import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
// import MasterView from "../views/MasterView.vue";
import Admin from "../views/Admin.vue";
import { jwtDecode } from "jwt-decode";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  // {
  //   path: "/master/:id",
  //   name: "master",
  //   component: MasterView,
  // },
  {
    path: "/admin",
    name: "admin",
    component: Admin,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/auth",
    name: "auth",
    component: () => import("../views/Auth.vue"),
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem("token");
      if (token) {
        next({ path: "/" });
      } else next();
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("refreshToken");

  if (token) {
    const tokenDecoder = jwtDecode(token);
    if (tokenDecoder.exp + 1000 * 60 * 24 < Date.now()) {
      try {
        const newToken = await refreshJwtToken(refreshToken);
        localStorage.setItem("token", newToken);
      } catch (error) {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        next({
          path: "/auth",
          query: { redirect: to.fullPath },
        });
        return;
      }
    }
  }

  if (to.meta.requiresAuth && !token) {
    next({
      path: "/auth",
      query: { redirect: to.fullPath },
    });
  } else {
    next();
  }
});

async function refreshJwtToken(refreshToken) {
  const response = await fetch(
    "https://leon-back-hiss.amvera.io/auth/refresh",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: refreshToken }),
    }
  );

  if (!response.ok) {
    throw new Error("Failed to refresh token");
  }

  const data = await response.json();
  return data.token;
}

export default router;
