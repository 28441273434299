<template>
  <div class="schedule">
    <div class="filter-title" @click="openCreate = !openCreate">
      Добавить расписание
    </div>
    <div class="grid-container create-schedule" v-if="openCreate">
      <div class="card">
        <div class="filter-title">Выберите мастера</div>
        <select v-model="masterId">
          <option v-for="master in masters" :key="master.id" :value="master.id">
            {{ master.firstName }} {{ master.lastName }}
          </option>
        </select>
      </div>

      <div class="card">
        <div class="filter-title">Добавьте месяц</div>
        <Calendar v-model="yearAndMonth" view="month" dateFormat="mm/yy" />
      </div>

      <div class="card">
        <div class="filter-title">Добавьте не рабочие дни</div>
        <Calendar
          v-model="dates"
          selectionMode="multiple"
          :minDate="minDate"
          :manualInput="false"
          dateFormat="yy-mm-dd"
        />
      </div>

      <div class="card">
        <div class="filter-title time">Добавьте рабочее время</div>
        <div class="time-selector">
          <div v-for="(time, index) in times" :key="index" class="time-entry">
            <InputNumber
              v-model="time.hours"
              :min="8"
              :max="21"
              showButtons
              :step="1"
              suffix=":00"
              placeholder="Часы"
            />
            <InputNumber
              v-model="time.minutes"
              :min="0"
              :max="59"
              showButtons
              :step="15"
              placeholder="Минуты"
            />
            <div class="btn-remove" @click="removeTime(index)">
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M12.0004 9.5L17.0004 14.5M17.0004 9.5L12.0004 14.5M4.50823 13.9546L7.43966 17.7546C7.79218 18.2115 7.96843 18.44 8.18975 18.6047C8.38579 18.7505 8.6069 18.8592 8.84212 18.9253C9.10766 19 9.39623 19 9.97336 19H17.8004C18.9205 19 19.4806 19 19.9084 18.782C20.2847 18.5903 20.5907 18.2843 20.7824 17.908C21.0004 17.4802 21.0004 16.9201 21.0004 15.8V8.2C21.0004 7.0799 21.0004 6.51984 20.7824 6.09202C20.5907 5.71569 20.2847 5.40973 19.9084 5.21799C19.4806 5 18.9205 5 17.8004 5H9.97336C9.39623 5 9.10766 5 8.84212 5.07467C8.6069 5.14081 8.38579 5.2495 8.18975 5.39534C7.96843 5.55998 7.79218 5.78846 7.43966 6.24543L4.50823 10.0454C3.96863 10.7449 3.69883 11.0947 3.59505 11.4804C3.50347 11.8207 3.50347 12.1793 3.59505 12.5196C3.69883 12.9053 3.96863 13.2551 4.50823 13.9546Z"
                      stroke="#f4bc83"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </g>
                </svg>
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div class="buttons">
        <ButtonComponent :name="'Добавить время'" @click="addTime" />
        <ButtonComponent :name="'Сохранить'" @click="sendRequest" />
      </div>
    </div>

    <ScheduleUpdateAdmin />
    <ModalInfo
      v-if="isModalVisible"
      :message="modalMessage"
      @close="isModalVisible = false"
    />
  </div>
</template>

<script>
import axios from "axios";
import Calendar from "primevue/calendar";
import InputNumber from "primevue/inputnumber";
import ButtonComponent from "@/components/ButtonComponent.vue";
import ScheduleUpdateAdmin from "@/components/ScheduleUpdateAdmin.vue";
import ModalInfo from "@/components/ModalInfo.vue";
import "primevue/resources/themes/saga-blue/theme.css"; // тема
import "primevue/resources/primevue.min.css"; // основные стили
import "primeicons/primeicons.css"; // иконки

export default {
  components: {
    Calendar,
    InputNumber,
    ButtonComponent,
    ScheduleUpdateAdmin,
    ModalInfo,
  },
  data() {
    return {
      dates: [],
      minDate: new Date(),
      times: [{ hours: 8, minutes: 0 }],
      masterId: null, // Пример masterId
      masters: [],
      openCreate: false,
      isModalVisible: false,
      modalMessage: "",
      yearAndMonth: null,
    };
  },
  methods: {
    addTime() {
      this.times.push({ hours: 8, minutes: 0 });
    },
    removeTime(index) {
      this.times.splice(index, 1);
    },
    sendRequest() {
      // const date = this.dates[0];
      // const year = date ? date.getFullYear() : null;
      // const month = date ? date.getMonth() + 1 : null;

      // Форматирование рабочего времени в строку
      const workingHours = this.times
        .map((time) => {
          const hours = time.hours.toString().padStart(2, "0");
          const minutes = time.minutes.toString().padStart(2, "0");
          return `${hours}:${minutes}`;
        })
        .join(",");

      // Форматирование нерабочих дней в массив
      const nonWorkingDays = this.dates.map((date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = (d.getMonth() + 1).toString().padStart(2, "0");
        const day = d.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
      });

      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const yearDate = this.yearAndMonth.getFullYear();
      const monthDate = (this.yearAndMonth.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const formattedMonth = monthDate.padStart(2, "0");

      // Формирование строки запроса
      const queryString = `https://leon-back-hiss.amvera.io/schedule?masterId=${this.masterId}&year=${yearDate}&month=${formattedMonth}&workingHours=${workingHours}`;
      // Отправка запроса
      axios
        .post(queryString, nonWorkingDays, { headers })
        .then((response) => {
          console.log("Запрос отправлен успешно", response);
          this.showModal("Данные успешно отправлены");
        })
        .catch((error) => {
          console.error("Ошибка при отправке запроса", error);
          if (error.response.status == "409") {
            this.showModal("Расписание уже существует");
          } else {
            this.showModal("Ошибка при отправке данных");
          }
        });
    },
    // Получение мастера
    getMaster() {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      axios
        .get("https://leon-back-hiss.amvera.io/masters/small", { headers })
        .then((response) => {
          this.masters = response.data;
          console.log(response.data);
        })
        .catch((error) => console.log(error));
    },
    showModal(message) {
      this.modalMessage = message;
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
        this.modalMessage = "";
      }, 2000);
    },
  },
  mounted() {
    this.getMaster();
  },
};
</script>

<style scoped>
.card {
  background-color: rgba(56, 56, 56, 0.6);
  backdrop-filter: blur(30px);
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.filter-title {
  font-size: 22px;
  color: #f4bc83;
  margin-bottom: 20px;
  font-weight: bold;
}

.time-selector {
  /* display: flex;
  flex-wrap: wrap;
  justify-content: space-between; */
  margin-top: 20px;
}

.time-entry {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.time-entry InputNumber {
  margin-right: 10px;
  width: 100px;
}

.time-entry Button {
  margin-left: 10px;
  background-color: #f4bc83;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.time-entry Button:hover {
  background-color: #f7bc83;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

.buttons Button {
  background-color: #f4bc83;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.buttons Button:hover {
  background-color: #f7bc83;
}

select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #2a2a2a;
  color: #fff;
  appearance: none;
  margin-top: 10px;
}

select:focus {
  border-color: #f4bc83;
  outline: none;
}

option {
  background-color: #2a2a2a;
  color: #fff;
}

option:checked,
option:hover {
  background-color: #f4bc83;
}

.calendar-container {
  text-align: center;
  margin-top: 20px;
}

.calendar {
  width: 100%;
  height: 300px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 20px;
}

svg {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

@media screen and (max-width: 320px) {
  .card {
    max-width: 300px;
  }
}
</style>
