<script>
// import Swiper core and required modules
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import axios from "axios";

// Import Swiper styles
import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import "swiper/css/scrollbar";
import "swiper/css/autoplay";

export default {
  name: "Slider",
  components: {
    Swiper,
    SwiperSlide,
    ButtonComponent,
  },
  setup() {
    const onSwiper = (swiper) => {
      // console.log(swiper);
    };
    const onSlideChange = () => {
      // console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
    };
  },
  data() {
    return {
      sliderData: [
        require("../assets/slider1.png"),
        require("../assets/slider2.png"),
        require("../assets/slider3.png"),
        // require("../assets/slider4.png"),
        require("../assets/slider5.png"),
        require("../assets/slider6.jpg"),
        require("../assets/slider7.jpg"),
        // require("../assets/slider8.jpg"),
      ],
    };
  },
  methods: {
    responseData() {
      axios
        .get("https://cepheus-serves-spring-production.up.railway.app/slider")
        .then((response) => {
          const data = response.data;
          this.sliderData = data;
        })
        .catch((error) => console.log(error));
    },
  },
  mounted() {
    // this.responseData();
  },
};
</script>

<template>
  <swiper
    :modules="modules"
    :slides-per-view="4"
    :space-between="30"
    navigation
    :pagination="{ clickable: true }"
    :scrollbar="{ draggable: true }"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
    :loop="true"
    :autoplay="{
      delay: 3000,
      disableOnInteraction: false,
    }"
    :breakpoints="{
      320: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1440: {
        slidesPerView: 4,
        spaceBetween: 40,
      },
    }"
  >
    <swiper-slide
      class="swiper-slide"
      v-for="item in sliderData"
      :key="item.id"
    >
      <img :src="item" alt="" />
    </swiper-slide>
  </swiper>
</template>

<style scoped>
img {
  max-width: 900px;
  max-height: 399px;
  border-radius: 20px;
  cursor: grab;
}

.swiper-slide {
  position: relative;
}

.swiper {
  padding-bottom: 30px !important;
}

.text {
  position: absolute;
  color: white;
  z-index: 1000;
  bottom: 100px;
  left: 170px;
  max-width: 700px;
  margin: 0 auto;
}

.text > h2 {
  font-size: 40px;
  font-weight: bold;
  text-shadow: 0 0 7px black;
}

.description {
  text-shadow: 0 0 7px black;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  padding: 1px;
}

.swiper .swiper-slide {
  /* margin-top: 50px; */
  border-radius: 12px;
}

.swiper-button-next {
  color: white !important;
}
.swiper-button-next::before {
}

@media screen and (max-width: 1580px) {
  img {
    width: 350px;
  }
}

@media screen and (max-width: 1280px) {
  img {
    width: 400px;
    margin: 0 auto;
    display: grid;
  }
}

@media screen and (max-width: 768px) {
  img {
    width: 300px;
    margin: 0 auto;
    display: grid;
  }
  .text {
    left: 70px;
  }
  .text > h2 {
    font-size: 30px;
  }
  .text > .description {
    max-width: 450px;
  }
}

@media screen and (max-width: 640px) {
  img {
    width: 300px;
    margin: 0 auto;
    display: grid;
    height: auto;
  }
  .text {
    left: 70px;
    bottom: 40px;
  }
  .text > h2 {
    font-size: 20px;
  }
  .text > .description {
    max-width: 450px;
  }
}

@media screen and (max-width: 480px) {
  img {
    width: 350px;
    margin: 0 auto;
    display: grid;
  }
  .text {
    left: 40px;
    bottom: 20px;
  }
  .text > h2 {
    font-size: 16px;
  }
  .text > .description {
    display: none;
  }
}

@media screen and (max-width: 375px) {
  img {
    width: 300px;
    margin: 0 auto;
    display: grid;
  }
}

@media screen and (max-width: 320px) {
  img {
    width: 250px;
    margin: 0 auto;
    display: grid;
  }
}
</style>
