<template>
  <div :style="linesStyle" class="lines">
    <div v-for="index in 5" :key="index" :style="lineStyle"></div>
  </div>
</template>

<script>
export default {
  name: "Lines",
  props: {
    top: {
      type: String,
      default: "0",
    },
    transform: {
      type: String,
      default: "45deg",
    },
  },
  computed: {
    linesStyle() {
      return {
        position: "absolute",
        top: this.top + "px",
        left: "50%",
        transform: "translateX(-50%)",
        width: "100%",
        zIndex: -2,
      };
    },
    lineStyle() {
      return {
        width: "100%",
        height: "4px",
        marginBottom: "15px",
        marginTop: "15px",
        transform: `rotate(${this.transform})`,
        borderBottom: "4px solid #f4bc83",
        borderRadius: "30px",
      };
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 480px) {
  .lines {
    display: none;
  }
}
</style>
