<template>
  <div v-if="isVisible" class="modal-overlay" @click.self="close">
    <div class="modal">
      <div class="modal-header">
        <h2 class="modal-title">{{ message }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isVisible: true,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal {
  background: #1a1a1a;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 800px;
  max-height: 90%;
  overflow-y: auto;
  animation: fadeIn 0.3s ease-out;
}

.modal-header {
  text-align: center;
}

.modal-title {
  font-size: 2.5em;
  color: #f4bc83;
  margin: 30px 0;
}

@media screen and (min-width: 768px) {
  .modal {
    max-width: 1000px;
  }

  .modal-title {
    font-size: 2em;
  }
}

@media screen and (min-width: 1024px) {
  .modal {
    max-width: 600px;
  }
}
</style>
