<template>
  <div class="button-container">
    <button class="button" :type="type" :disabled="disabled">{{ name }}</button>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    type: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.button {
  background-color: #f4bc83;
  color: white;
  padding: 10px 40px;
  border: 2px #f4bc83 solid;
  transition: background-color 0.4s cubic-bezier(0, 0.55, 0.45, 1) 0s;
  cursor: pointer;
  font-size: 22px;
  font-family: "Poiret One";
  border-radius: 25px;
}

.button:hover {
  background-color: #383838;
}

.button:disabled {
  background-color: #cccccc;
  border-color: #cccccc;
}

@media screen and (max-width: 480px) {
  .button {
    padding: 10px 10px;
  }
}
</style>
