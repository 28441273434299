<script>
// import Swiper core and required modules
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import axios from "axios";

// Import Swiper styles
import "swiper/css";
// import 'swiper/css/navigation';
// import "swiper/css/pagination";
// import 'swiper/css/scrollbar';
// import "swiper/css/bundle";
import "swiper/css/autoplay";

export default {
  name: "Slider",
  components: {
    Swiper,
    SwiperSlide,
    ButtonComponent,
  },
  setup() {
    const onSwiper = (swiper) => {
      // console.log(swiper);
    };
    const onSlideChange = () => {
      // console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
    };
  },
  data() {
    return {
      sliderData: [
        {
          img: require("../assets/reviewsTest1.jpg"),
          text: "Вчера была на процедуре у мастера Арины , сделали кератин, спасибо большое за прекрасную работу. Я в полном восторге от своих волос",
          name: "Светлана"
        },
        {
          img: require("../assets/reviewsTest2.jpg"),
          text: "Была на процедуре у Елены 17.12.23. (кератин)Все прошло просто великолепно , на высшем уровне. Прекрасная, уютная студия а главное в центре города. Хочу сказать, что Лена мастер своего дела. Перед процедурой она проверила состояние моих волос, подобрала нужный состав😍 уже прошло 2,5 месяца а волосы все так же шикарны, я просто в восторге 😍 они шикарные, послушные, мягкие)",
          name: "​Кристина"
        },
        {
          img: require("../assets/reviewsTest3.jpg"),
          text: "Всё прошло на Ура! Процедуру кератинового выпрямления я делала впервые. После волосы гладкие, блестящие, ухоженные. Укладка занимает меньше времени. Спасибо мастеру Арине, за грамотный подход к работе, во время процедуры отвечала на все волнующие вопросы, дала рекомендации по дальнейшему уходу, угостила кофе- сервис на высшем уровне☺️ Обязательно вернусь ещё🤍",
          name: "Valeria"
        },
        {
          img: require("../assets/reviewsTest4.jpg"),
          text: "Спасибо мастеру Арине за процедуру кератинового выпрямления волос 🤗 Всё очень понравилось, сервис на высшем уровне, индивидуальный подход, дали советы по уходу за волосами Волосы из убитых стали идеальными, на ощупь очень мягкие, а главное для меня - прямые, как я и хотела Обязательно приду ещё ❤️",
          name: "Ксения"
        },
      ],
    };
  },
  methods: {
    responseData() {
      axios
        .get("https://cepheus-serves-spring-production.up.railway.app/slider")
        .then((response) => {
          const data = response.data;
          this.sliderData = data;
        })
        .catch((error) => console.log(error));
    },
  },
  mounted() {
    // this.responseData();
  },
};
</script>

<template>
  <swiper
    :modules="modules"
    :slides-per-view="2"
    :space-between="30"
    navigation
    :pagination="{ clickable: true }"
    :scrollbar="{ draggable: true }"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
    :loop="true"
    :autoplay="{
      delay: 3000, // время автопрокрутки в миллисекундах
      disableOnInteraction: false, // продолжать автопрокрутку после взаимодействия с пользователем
    }"
    :breakpoints="{
      320: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      1440: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
    }"
    :slideVisibleClass="'swiper-slide-visible'"
    :watchSlidesVisibility="true"
  >
    <swiper-slide
      class="swiper-slide"
      v-for="item in sliderData"
      :key="item.id"
    >
      <!-- <img
        class="img"
        :src="'data:image/png;base64,' + item?.bytes"
        :alt="item?.name"
      /> -->
      <div class="reviews">
        <img :src="item.img" alt="" />
        <div class="reviews-text">
          <div class="name">
            <h2>{{ item.name }}</h2>
          </div>
          <p class="description">
            {{ item.text }}
          </p>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>

<style scoped>
img {
  max-width: 320px;
  /* max-height: 500px; */
  /* border-radius: 20px; */
  cursor: grab;
}

.swiper-slide {
  position: relative;
}

.swiper {
  padding-bottom: 30px !important;
}

.swiper .swiper-slide {
  /* margin-top: 50px; */
  /* border-radius: 12px; */
}

.swiper-button-next {
  color: white !important;
}
.swiper-button-next::before {
}

.reviews-text {
  background-color: #1a1a1a;
  padding: 40px;
}

.reviews {
  display: flex;
  margin-right: 20px;
}

.name {
  font-size: 48px;
  color: #f4bc83;
}

.description {
  font-size: 18px;
  margin-top: 40px;
  width: 368px;
  height: 176px;
  word-break: break-word;
  font-family: "Montserrat Medium";
}

@media screen and (max-width: 712px) {
  img {
    padding-left: 10px;
  }
  .name {
    font-size: 24px;
  }
  .description {
    font-size: 14px;
    height: auto;
  }
  .reviews-text {
    font-size: 20px;
    padding: 20px 0 0 10px;
  }
  .reviews {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 320px) {
  .description {
    word-break: break-word;
    width: 310px;
  }
}
</style>
