<template>
  <div v-if="this.$route.name !== 'admin'" >
    <div class="footer-map mini">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2263.0279998055285!2d65.33668447712256!3d55.44474371567637!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x43b7bc4c45523061%3A0xa0f212b33a0fed8d!2z0YPQuy4g0JvQtdC90LjQvdCwLCAzOSwg0JrRg9GA0LPQsNC9LCDQmtGD0YDQs9Cw0L3RgdC60LDRjyDQvtCx0LsuLCDQoNC-0YHRgdC40Y8sIDY0MDAwMA!5e0!3m2!1sru!2sfi!4v1719484258611!5m2!1sru!2sfi"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    <footer>
      <div class="footer-map big">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2263.0279998055285!2d65.33668447712256!3d55.44474371567637!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x43b7bc4c45523061%3A0xa0f212b33a0fed8d!2z0YPQuy4g0JvQtdC90LjQvdCwLCAzOSwg0JrRg9GA0LPQsNC9LCDQmtGD0YDQs9Cw0L3RgdC60LDRjyDQvtCx0LsuLCDQoNC-0YHRgdC40Y8sIDY0MDAwMA!5e0!3m2!1sru!2sfi!4v1719484258611!5m2!1sru!2sfi"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div class="footer-text">
        <div class="img-container">
          <img src="@/assets/footer.png" alt="footer" class="img-footer" />
        </div>
        <div class="footer-content">
          <div class="footer-nav">
            <ul class="nav-list">
              <li class="li-list">
                <a href="#aboutUs" class="link">О нас</a>
              </li>
              <li class="li-list">
                <a href="#works" class="link">Наши работы</a>
              </li>
              <li class="li-list">
                <a href="#masters" class="link">Мастера</a>
              </li>
              <li class="li-list">
                <a href="#reviews" class="link">Отзывы</a>
              </li>
              <li class="li-list">
                <a href="#homeCare" class="link">Домашний уход</a>
              </li>
            </ul>
          </div>
          <div class="links-and-social-links">
            <div class="footer-links">
              <a href="#calendar">
                <ButtonComponent :name="'Записаться'" :type="button" @click="scrollToTpop" />
              </a>
              <a href="tel:+7 (919) 576-00-69" class="link link-phone"
                >+7 (919) 576-00-69</a
              >
            </div>
            <div class="social-links">
              <a target="_blank" href="https://www.instagram.com/leon.studiokeratin?igsh=MTlqYW9kc2ExN2Nseg==" class="social-link-svg">
                <svg
                  width="48.000000"
                  height="48.000000"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <defs>
                    <clipPath id="clip36_856">
                      <rect
                        id="Platform=Instagram, Color=Negative"
                        width="48.000000"
                        height="48.000000"
                        fill="white"
                        fill-opacity="0"
                      />
                    </clipPath>
                  </defs>
                  <rect
                    id="Platform=Instagram, Color=Negative"
                    width="48.000000"
                    height="48.000000"
                    fill="#FFFFFF"
                    fill-opacity="0"
                  />
                  <g clip-path="url(#clip36_856)">
                    <path
                      class="social-link-svg-fill"
                      id="Vector"
                      d="M24 4.32C30.41 4.32 31.17 4.35 33.69 4.46C36.03 4.56 37.3 4.95 38.14 5.28C39.26 5.71 40.06 6.24 40.9 7.07C41.74 7.92 42.26 8.71 42.69 9.83C43.02 10.67 43.41 11.95 43.51 14.28C43.63 16.81 43.65 17.57 43.65 23.98C43.65 30.39 43.63 31.15 43.51 33.67C43.41 36.01 43.02 37.28 42.69 38.12C42.26 39.24 41.73 40.05 40.9 40.88C40.05 41.72 39.26 42.24 38.14 42.67C37.3 43 36.02 43.39 33.69 43.5C31.16 43.61 30.4 43.64 24 43.64C17.58 43.64 16.82 43.61 14.3 43.5C11.96 43.39 10.69 43 9.85 42.67C8.73 42.24 7.93 41.71 7.09 40.88C6.25 40.04 5.73 39.24 5.3 38.12C4.97 37.28 4.58 36 4.48 33.67C4.36 31.14 4.34 30.38 4.34 23.98C4.34 17.56 4.36 16.8 4.48 14.28C4.58 11.94 4.97 10.67 5.3 9.83C5.73 8.71 6.26 7.91 7.09 7.07C7.94 6.23 8.73 5.71 9.85 5.28C10.69 4.95 11.97 4.56 14.3 4.46C16.82 4.35 17.58 4.32 24 4.32ZM24 0C17.48 0 16.66 0.02 14.1 0.14C11.55 0.25 9.8 0.66 8.28 1.25C6.7 1.87 5.36 2.69 4.03 4.03C2.69 5.36 1.87 6.7 1.25 8.27C0.66 9.8 0.25 11.55 0.14 14.1C0.02 16.66 0 17.48 0 24C0 30.51 0.02 31.33 0.14 33.89C0.25 36.44 0.66 38.19 1.25 39.71C1.87 41.29 2.69 42.63 4.03 43.96C5.36 45.3 6.7 46.12 8.27 46.73C9.8 47.32 11.55 47.73 14.1 47.85C16.65 47.96 17.47 47.99 23.99 47.99C30.5 47.99 31.32 47.96 33.88 47.85C36.43 47.73 38.18 47.32 39.7 46.73C41.27 46.12 42.61 45.3 43.95 43.96C45.28 42.63 46.1 41.29 46.71 39.72C47.3 38.19 47.71 36.45 47.83 33.9C47.94 31.34 47.97 30.52 47.97 24C47.97 17.49 47.94 16.67 47.83 14.11C47.71 11.56 47.3 9.81 46.71 8.29C46.12 6.7 45.3 5.36 43.96 4.03C42.63 2.7 41.29 1.87 39.72 1.26C38.19 0.67 36.45 0.26 33.9 0.14C31.33 0.02 30.51 0 24 0Z"
                      fill="#383838"
                      fill-opacity="1.000000"
                      fill-rule="nonzero"
                    />
                    <path
                      class="social-link-svg-fill"
                      id="Vector"
                      d="M24 11.67C17.19 11.67 11.67 17.19 11.67 24C11.67 30.8 17.19 36.32 24 36.32C30.8 36.32 36.32 30.8 36.32 24C36.32 17.19 30.8 11.67 24 11.67ZM24 31.99C19.58 31.99 16 28.41 16 24C16 19.58 19.58 16 24 16C28.41 16 31.99 19.58 31.99 24C31.99 28.41 28.41 31.99 24 31.99Z"
                      fill="#383838"
                      fill-opacity="1.000000"
                      fill-rule="nonzero"
                    />
                    <path
                      class="social-link-svg-fill"
                      id="Vector"
                      d="M39.69 11.18C39.69 12.77 38.4 14.06 36.81 14.06C35.22 14.06 33.93 12.76 33.93 11.18C33.93 9.59 35.23 8.3 36.81 8.3C38.4 8.3 39.69 9.59 39.69 11.18Z"
                      fill="#383838"
                      fill-opacity="1.000000"
                      fill-rule="nonzero"
                    />
                  </g>
                </svg>
              </a>
              <a target="_blank" href="https://vk.com/lvova.hair" class="social-link-svg">
                <svg
                  width="48.000000"
                  height="48.000000"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <desc>Created with Pixso.</desc>
                  <defs>
                    <clipPath id="clip36_854">
                      <rect
                        id="Platform=VK, Color=Negative"
                        width="48.000000"
                        height="48.000000"
                        fill="white"
                        fill-opacity="0"
                      />
                    </clipPath>
                  </defs>
                  <rect
                    id="Platform=VK, Color=Negative"
                    width="48.000000"
                    height="48.000000"
                    fill="#FFFFFF"
                    fill-opacity="0"
                  />
                  <g clip-path="url(#clip36_854)">
                    <path
                      class="social-link-svg-fill"
                      id="Vector"
                      d="M3.37 3.37C0 6.74 0 12.17 0 23.04L0 24.96C0 35.82 0 41.25 3.37 44.62C6.74 48 12.17 48 23.04 48L24.96 48C35.82 48 41.25 48 44.62 44.62C48 41.25 48 35.82 48 24.96L48 23.04C48 12.17 48 6.74 44.62 3.37C41.25 0 35.82 0 24.96 0L23.04 0C12.17 0 6.74 0 3.37 3.37ZM8.1 14.6C8.36 27.08 14.6 34.58 25.54 34.58L26.16 34.58L26.16 27.44C30.18 27.84 33.22 30.78 34.43 34.58L40.12 34.58C38.56 28.9 34.45 25.76 31.89 24.56C34.45 23.08 38.05 19.48 38.91 14.6L33.75 14.6C32.63 18.56 29.32 22.16 26.16 22.5L26.16 14.6L21 14.6L21 28.44C17.79 27.64 13.76 23.76 13.58 14.6L8.1 14.6Z"
                      fill="#383838"
                      fill-opacity="1.000000"
                      fill-rule="evenodd"
                    />
                  </g>
                </svg>
              </a>
              <a target="_blank" href="https://t.me/lvova_hair" class="social-link-svg">
                <svg
                  width="48.000000"
                  height="48.000000"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <desc>Created with Pixso.</desc>
                  <defs>
                    <clipPath id="clip36_855">
                      <rect
                        id="Platform=Telegram, Color=Negative"
                        width="48.000000"
                        height="48.000000"
                        fill="white"
                        fill-opacity="0"
                      />
                    </clipPath>
                  </defs>
                  <rect
                    id="Platform=Telegram, Color=Negative"
                    width="48.000000"
                    height="48.000000"
                    fill="#FFFFFF"
                    fill-opacity="0"
                  />
                  <g clip-path="url(#clip36_855)">
                    <path
                      class="social-link-svg-fill"
                      id="Vector"
                      d="M48 24C48 37.25 37.25 48 24 48C10.74 48 0 37.25 0 24C0 10.74 10.74 0 24 0C37.25 0 48 10.74 48 24ZM24.86 17.71C22.52 18.68 17.86 20.69 10.86 23.74C9.72 24.19 9.13 24.64 9.07 25.07C8.98 25.8 9.9 26.09 11.14 26.48C11.31 26.53 11.49 26.59 11.67 26.64C12.89 27.04 14.54 27.51 15.4 27.53C16.17 27.54 17.04 27.22 18 26.57C24.54 22.15 27.91 19.92 28.12 19.87C28.27 19.84 28.48 19.8 28.62 19.92C28.76 20.05 28.75 20.28 28.73 20.35C28.64 20.73 25.05 24.07 23.19 25.8C22.61 26.34 22.2 26.72 22.12 26.81C21.93 27 21.74 27.19 21.55 27.36C20.42 28.46 19.56 29.28 21.6 30.63C22.58 31.27 23.37 31.81 24.15 32.34C25 32.92 25.86 33.5 26.96 34.23C27.24 34.41 27.51 34.6 27.77 34.79C28.76 35.5 29.66 36.13 30.76 36.03C31.4 35.97 32.06 35.37 32.4 33.57C33.2 29.32 34.76 20.11 35.12 16.32C35.15 15.98 35.11 15.56 35.08 15.37C35.05 15.19 34.98 14.92 34.74 14.72C34.45 14.49 34.01 14.44 33.81 14.44C32.91 14.46 31.52 14.94 24.86 17.71Z"
                      fill="#383838"
                      fill-opacity="1.000000"
                      fill-rule="evenodd"
                    />
                  </g>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import ButtonComponent from "@/components/ButtonComponent.vue";

export default {
  components: {
    ButtonComponent,
  },

  methods: {
    scrollToTpop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })
  },
  },

  name: "Footer",
};
</script>

<style scoped>
footer {
  margin-top: 150px;
  display: flex;
  position: relative; /* Обеспечивает относительное позиционирование для абсолютных потомков */
  overflow: hidden; /* Скрывает выходящие за пределы части */
  width: 100%;
  height: 480px;
}

.footer-map {
  width: 100%;
  max-width: 1200px; /* Максимальная ширина карты */
  height: 0;
  padding-bottom: 56.25%; /* Соотношение сторон 16:9 */
  position: relative;
  margin: 0 auto; /* Центрирование */
}

.footer-map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 480px;
  border: 0;
}

.footer-text {
  width: 100%;
  height: auto;
  /* padding: 20px; */
}

.img-container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.img-footer {
  width: 100%;
  height: 100%;
  max-width: 100%;
  position: absolute;
}

.li-list {
  list-style-type: none;
  font-size: 28px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 50px;
}

.li-list:hover > a {
  color: #f4bc83;
  transition: color 0.3s cubic-bezier(0, 0.55, 0.45, 1) 0s;
}

.footer-nav {
  margin-top: 20px;
  margin-left: 20px;
}

.footer-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
  justify-content: space-evenly;
  margin-top: 50px;
}

.footer-links {
  display: grid;
  justify-items: end;
}

.link-phone {
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.social-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.social-link-svg {
  margin: 10px;
  /* max-width: 30px; */
}

.social-link-svg:hover .social-link-svg-fill {
  fill: #f4bc83;
  transition: fill 0.3s cubic-bezier(0, 0.55, 0.45, 1) 0s; /* Плавный переход цвета */
}


.mini {
  display: none;
}

/* Media queries for different screen sizes */
@media screen and (max-width: 768px) {
  .footer-map {
    height: 150px;
  }
  .footer-text {
    padding: 10px;
  }
  .li-list {
    font-size: 20px;
  }
  .link-phone {
    font-size: 14px;
  }
  .social-link-svg {
    /* max-width: 20px; */
  }
  .mini {
    display: flex;
    margin-top: 150px;
  }
  .big {
    display: none;
  }
  footer {
    margin-top: 0;
    height: auto;
  }

  .footer-map {
    height: 100px;
  }
  .footer-text {
    margin-top: 50px;
    padding: 0;
  }
  .li-list {
    font-size: 20px;
    margin-left: 10px;
  }
  .link-phone {
    font-size: 22px;
  }
  .social-link-svg {
    /* max-width: 15px; */
  }
  .footer-links {
    display: grid;
    justify-content: center;
  }
  .nav-list {
    display: flex;
  }
  .footer-nav {
    margin-top: 20px;
    margin-right: 10px;
  }
  .social-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px;
  }
  .footer-content {
    margin-right: 0;
  }
}

@media screen and (max-width: 712px) {
  .li-list {
    /* margin-right: 20px; */
    font-size: 18px;
  }
  .nav-lis {
    flex-direction: column;
  }
}

@media screen and (max-width: 640px) {
  .nav-list {
    flex-direction: column;
    margin-top: 10px;
  }
  
}

@media screen and (max-width: 480px) {
  .footer-map {
    height: 100px;
  }
  .footer-text {
    margin-top: 70px;
  }
  .li-list {
    font-size: 18px;
    margin-left: 10px;
  }
  .link-phone {
    font-size: 22px;
  }
  .social-link-svg {
    /* max-width: 15px; */
  }
  .footer-links {
    display: grid;
    justify-content: center;
  }
  .nav-list {
    display: flex;
    flex-direction: column;
  }
  .footer-nav {
    margin-top: 20px;
  }
  .social-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px;
  }
  footer {
    margin-top: 140px;
  }
}

@media screen and (max-width: 375px) {
  footer {
    margin-top: 200px;
  }

  .footer-nav {
    margin-left: 0;
  }
}

@media screen and (max-width: 320px) {
  footer {
    margin-top: 230px;
  }
  .li-list {
    font-size: 16px;
  }
  .social-link-svg {
    width: 40px;
    height: 40px;
  }
  .link-phone {
    font-size: 18px;
  }
}
</style>
