<template>
  <div class="hint-container">
    <div class="hint-icon" @click="toggleHint">?</div>
    <div v-if="showHint" class="hint-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showHint: false,
    };
  },
  methods: {
    toggleHint() {
      this.showHint = !this.showHint;
    },
  },
};
</script>

<style scoped>
.hint-container {
  position: relative;
  display: flex;
  justify-content: center;
}

.hint-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  text-align: center;
  cursor: pointer;
}

.hint-content {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(56, 56, 56, 0.6);
  backdrop-filter: blur(30px);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 4px;
  width: 200px;
  z-index: 1000;
}
</style>
