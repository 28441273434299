<template>
  <div>
    <header v-if="this.$route.name !== 'admin'">
      <nav>
        <div class="container-logo">
          <a href="/">
            <img :src="logo" class="logo" alt="Логотип" />
          </a>
        </div>
        <div class="container-nav">
          <ul class="nav-list">
            <li class="li-list">
              <a href="#aboutUs" class="link">О нас</a>
            </li>
            <li class="li-list">
              <a href="#works" class="link">Наши работы</a>
            </li>
            <li class="li-list">
              <a href="#masters" class="link">Мастера</a>
            </li>
            <li class="li-list">
              <a href="#reviews" class="link">Отзывы</a>
            </li>
            <li class="li-list">
              <a href="#homeCare" class="link">Домашний уход</a>
            </li>
          </ul>
        </div>
        <!-- <button id="toggleButton" @click="toggleMenu()">Меню</button> -->
        <button class="setting-btn" @click="toggleMenu()">
          <span class="bar bar1"></span>
          <span class="bar bar2"></span>
          <span class="bar bar1"></span>
        </button>
        <div :class="['container-nav-small', { open: isOpen }]">
          <ul class="nav-list-mini">
            <li class="li-list-mini">
              <a href="#aboutUs" class="link">О нас</a>
            </li>
            <li class="li-list-mini">
              <a href="#works" class="link">Наши работы</a>
            </li>
            <li class="li-list-mini">
              <a href="#masters" class="link">Мастера</a>
            </li>
            <li class="li-list-mini">
              <a href="#reviews" class="link">Отзывы</a>
            </li>
            <li class="li-list-mini">
              <a href="#homeCare" class="link">Домашний уход</a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logo: require("../assets/logo.png"),
      isOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style scoped>
header {
  max-width: 1778px;
  height: 142px;
  margin: 0 auto;
}

.logo {
  width: 127px;
  height: 142px;
}

nav {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

ul {
  display: flex;
}

.li-list {
  margin-right: 80px;
  list-style-type: none;
  font-size: 30px;
  font-weight: 400;
  line-height: 22px;
}

.li-list:last-child {
  /* margin-right: 0; */
}

.li-list:hover > a {
  color: #f4bc83;
  transition: color 0.3s cubic-bezier(0, 0.55, 0.45, 1) 0s; /* Плавный переход цвета */
}

.setting-btn {
  display: none;
}

/* Боковое меню */
.container-nav-small {
  display: none;
}

@media screen and (max-width: 1440px) {
  .li-list {
    margin-right: 60px;
  }
}

@media screen and (max-width: 1024px) {
  .li-list {
    margin-right: 30px;
  }
}

@media screen and (max-width: 768px) {
  .li-list {
    margin-right: 20px;
    font-size: 22px;
  }
}

@media screen and (max-width: 712px) {
  .li-list {
    margin-right: 20px;
    font-size: 18px;
  }
}

@media screen and (max-width: 640px) {
  .container-nav {
    display: none;
  }
  .container-nav-small {
    position: fixed;
    top: 0;
    right: -50%;
    width: 50%;
    height: 100%;
    background-color: rgba(56, 56, 56, 0.6);
    backdrop-filter: blur(30px);
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    transition: right 0.3s ease;
    z-index: 1000; /* Убедитесь, что меню находится выше других элементов */
    display: flex;
    flex-direction: column;
  }

  .nav-list-mini {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .li-list-mini {
    padding: 15px;
    /* border-bottom: 1px solid #ddd; */
    margin-bottom: 20px;
  }

  .link {
    text-decoration: none;
    color: #f4bc83;
    display: block;
    font-size: 22px;
  }

  .container-nav-small.open {
    right: 0;
  }

  #toggleButton {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1001; /* Убедитесь, что кнопка находится выше меню */
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    border: none;
    cursor: pointer;
  }

  ul {
    flex-direction: column;
  }

  .setting-btn {
    width: 45px;
    height: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;
    background-color: #f4bc83;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    box-shadow: 0px 0px 0px 2px rgba(244, 188, 131, 0.4);
    z-index: 1001;
    margin-right: 10px;
    position: fixed;
    right: 0px;
    top: 10px;
  }
  .bar {
    width: 50%;
    height: 2px;
    background-color: #383838;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 2px;
  }
  .bar::before {
    content: "";
    width: 2px;
    height: 2px;
    background-color: rgb(126, 117, 255);
    position: absolute;
    border-radius: 50%;
    border: 2px solid #383838;
    transition: all 0.3s;
    box-shadow: 0px 0px 5px white;
  }
  .bar1::before {
    transform: translateX(-4px);
  }
  .bar2::before {
    transform: translateX(4px);
  }
  .setting-btn:hover .bar1::before {
    transform: translateX(4px);
  }
  .setting-btn:hover .bar2::before {
    transform: translateX(-4px);
  }
}

@media screen and (max-width: 480px) {
  .logo {
    width: 80px;
    height: 90px;
    margin-left: 10px;
  }

  .li-list {
    font-size: 26px;
  }
}
</style>
